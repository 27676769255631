import React, { lazy } from "react";

const
	viewComponents = {
		AboutContactLenders: lazy(() => import("../views/about/contact-us/lenders")),
		AboutContactUs: lazy(() => import("../views/about/contact-us")),
		AboutContactOfficeConsumerAdvocacy: lazy(() => import("../views/about/contact-us/office-consumer-advocacy")),
		AboutLanding: lazy(() => import("../views/about/index")),
		AccountAccessLanding: lazy(() => import("../views/tools-resources/account-access/index")),
		ActiveVolunteerProgram: lazy(() => import("../views/funding-opportunities/other-educational-aid/active-volunteer-program")),
		AboutUs: lazy(() => import("../views/about/about-us")),
		AboutCareers: lazy(() => import("../views/about/careers")),
		AfterYouApplyRenew: lazy(() => import("../views/college-planning/fafsa/after-you-apply-renew")),
		AfterYouApplyWorkStudyEmployment: lazy(() => import("../views/funding-opportunities/work-study-employment/after-you-apply")),
		AfterYouApplyPaTip: lazy(() => import("../views/funding-opportunities/pa-tip/after-you-apply")),
		AidForMilitaryNationalGuard: lazy(() => import("../views/funding-opportunities/aid-for-military-national-guard/index")),
		Appeals: lazy(() => import("../views/funding-opportunities/loan-relief-for-nurses/appeals")),
		Apply: lazy(() => import("../views/funding-opportunities/work-study-employment/apply")),
		ApplyRenew: lazy(() => import("../views/college-planning/fafsa/apply-renew")),
		BlindDeaf: lazy(() => import("../views/funding-opportunities/other-educational-aid/blind-deaf")),
		BoardMeetings: lazy(() => import("../views/about/board-members/board-meetings")),
		BoardMembers: lazy(() => import("../views/about/board-members")),
		CareerFAQ: lazy(() => import("../views/about/careers/career-faq")),
		CareersRedirect: lazy(() => import("../views/about/careers/redirect")),
		ChafeeProgram: lazy(() => import("../views/funding-opportunities/other-educational-aid/chafee-program")),
		ChildrenPowMiaSoldiers: lazy(() => import("../views/funding-opportunities/aid-for-military-national-guard/children-pow-mia-soldiers")),
		CollegeFairs: lazy(() => import("../views/college-planning/college-fairs")),
		CollegeMarketing: lazy(() => import("../views/orphans/college")),
		CollegePlanningLanding: lazy(() => import("../views/college-planning")),
		CommercialProducts: lazy(() => import("../views/orphans/commercial-products")),
		CommunityPartnersLanding: lazy(() => import("../views/schools-partners/community-partners/index")),
		CounselorsPartners: lazy(() => import("../views/tools-resources/counselors-partners")),
		CounselorsPartnersFafsaEvents: lazy(() => import("../views/tools-resources/counselors-partners/fafsa-events")),
		CounselorsPartnersFinancialAidNight: lazy(() => import("../views/tools-resources/counselors-partners/financial-aid-night")),
		DefaultCollections: lazy(() => import("../views/about/contact-us/default-collections")),
		DoingBusinessWithPheaa: lazy(() => import("../views/about/doing-business-with-pheaa")),
		EducationPlannerLanding: lazy(() => import("../views/college-planning/education-planner/index")),
		EqualOpportunityEmployment: lazy(() => import("../views/about/careers/equal-opportunity-employment")),
		EligibleSchools: lazy(() => import("../views/funding-opportunities/pa-tip/eligible-schools")),
		EmployeeBenefits: lazy(() => import("../views/about/careers/employee-benefits")),
		ExecutiveTeam: lazy(() => import("../views/about/executive-team")),
		FafsaFaq: lazy(() => import("../views/college-planning/fafsa/fafsa-faq")),
		FafsaHelpEvents: lazy(() => import("../views/college-planning/fafsa/help-events")),
		FafsaLanding: lazy(() => import("../views/college-planning/fafsa/index")),
		FafsaPrepare: lazy(() => import("../views/college-planning/fafsa/prepare")),
		FinancialReports: lazy(() => import("../views/about/investor-information/financial-reports")),
		FiveSteps: lazy(() => import("../views/college-planning/five-steps/five-steps")),
		FosterEducation: lazy(() => import("../views/funding-opportunities/other-educational-aid/foster-education")),
		FundingOpportunitiesLanding: lazy(() => import("../views/funding-opportunities")),
		FosterEducationFAQ: lazy(() => import("../views/funding-opportunities/other-educational-aid/foster-education-faq")),
		FrnTrusts: lazy(() => import("../views/about/investor-information/frn-trusts")),
		GrowPA: lazy(() => import("../views/funding-opportunities/grow-pa-scholarship-grant-program/index")),
		GrowPAFAQ: lazy(() => import("../views/funding-opportunities/grow-pa-scholarship-grant-program/faq")),
		GrowPATuitionWaiver: lazy(() => import("../views/funding-opportunities/grow-pa-tuition-waiver")),
		GrantsLanding: lazy(() => import("../views/grants")),
		GrantUs: lazy(() => import("../views/grants/grant-us/index")),
		GrantUsFAQ: lazy(() => import("../views/grants/grant-us/faq")),
		GrantUsSchoolTraining: lazy(() => import("../views/grants/grant-us/school-training")),
		GrantUsStudentTraining: lazy(() => import("../views/grants/grant-us/student-training")),
		GrantsStateGrantProgram: lazy(() => import("../views/grants/state-grant-program/index")),
		Hersheypark: lazy(() => import("../views/orphans/hersheypark")),
		HomePage: lazy(() => import("../views/App/App")),
		HumanResources: lazy(() => import("../views/about/contact-us/human-resources")),
		IndependentRegisteredMunicipalAdvisor: lazy(() => import("../views/about/investor-information/independent-registered-municipal-advisor")),
		InvestorInformationLanding: lazy(() => import("../views/about/investor-information/index")),
		HigherEducationAccessPartners: lazy(() => import("../views/schools-partners/k12-counselors/higher-education-access-partners")),
		HelpfulWebsites: lazy(() => import("../views/schools-partners/k12-counselors/helpful-websites")),
		K12Landing: lazy(() => import("../views/schools-partners/k12-counselors/index")),
		K12PheaaResources: lazy(() => import("../views/schools-partners/k12-counselors/pheaa-resources")),
		K12Publications: lazy(() => import("../views/schools-partners/k12-counselors/publications")),
		LoanDefaultFAQ: lazy(() => import("../views/loan-default/faq")),
		LoanForgiveness: lazy(() => import("../views/funding-opportunities/loan-forgiveness")),
		LoanReliefNurses: lazy(() => import("../views/funding-opportunities/loan-relief-for-nurses/index")),
		Materials3rdGrade: lazy(() => import("../views/schools-partners/k12-counselors/materials-3rd-grade")),
		Materials5thGrade: lazy(() => import("../views/schools-partners/k12-counselors/materials-5th-grade")),
		Materials8thGrade: lazy(() => import("../views/schools-partners/k12-counselors/materials-8th-grade")),
		Materials11thGrade: lazy(() => import("../views/schools-partners/k12-counselors/materials-11th-grade")),
		MilitaryFamilyEducation: lazy(() => import("../views/funding-opportunities/aid-for-military-national-guard/military-family-education")),
		MilitaryStateGrantProgram: lazy(() => import("../views/funding-opportunities/aid-for-military-national-guard/state-grant-program")),
		Nets: lazy(() => import("../views/funding-opportunities/other-educational-aid/nets")),
		NetsRepayment: lazy(() => import("../views/funding-opportunities/other-educational-aid/nets-repayment")),
		NetsFaq: lazy(() => import("../views/funding-opportunities/other-educational-aid/nets-faq")),
		NationalGuardEAP: lazy(() => import("../views/funding-opportunities/aid-for-military-national-guard/national-guard-eap")),
		NetRejectRate: lazy(() => import("../views/about/investor-information/net-reject-rate")),
		OfficeConsumerAdvocacyResources: lazy(() => import("../views/about/contact-us/office-consumer-advocacy-resources")),
		OnlineServicesTermsOfUse: lazy(() => import("../views/about/online-services-terms-of-use")),
		OtherEducationalAid: lazy(() => import("../views/funding-opportunities/other-educational-aid")),
		PaForwardDisclosures: lazy(() => import("../views/student-loans/pa-forward/disclosures")),
		PAForwardFacebook: lazy(() => import("../views/orphans/paf-facebook")),
		PAForwardGoogle: lazy(() => import("../views/orphans/paf-google")),
		PAForwardPF004: lazy(() => import("../views/orphans/paf-pf004")),
		PAForwardSpotify: lazy(() => import("../views/orphans/paf-spotify")),
		PAForwardTwitter: lazy(() => import("../views/orphans/paf-twitter")),
		PaForwardFAQ: lazy(() => import("../views/student-loans/pa-forward/faq")),
		PaForwardGraduateFAQ: lazy(() => import("../views/student-loans/pa-forward/graduate-faq")),
		PaForwardParentLoanCompare: lazy(() => import("../views/student-loans/pa-forward/parent-loan-compare")),
		PaForwardPrivacyPolicy: lazy(() => import("../views/student-loans/pa-forward/privacy-policy")),
		PaForwardReferralPartners: lazy(() => import("../views/student-loans/pa-forward/referral-partners")),
		LenderToolkit: lazy(() => import("../views/orphans/lender-toolkit")),
		PageNotFound: lazy(() => import("../views/orphans/404")),
		PaHelpsLanding: lazy(() => import("../views/funding-opportunities/pa-helps/index")),
		ParentFAQ: lazy(() => import("../views/student-loans/pa-forward/parent-faq")),
		ParticipatingInstitutions: lazy(() => import("../views/funding-opportunities/other-educational-aid/rtss/participating-institutions")),
		ParticipatingPathPartners: lazy(() => import("../views/funding-opportunities/other-educational-aid/participating-path-partners")),
		Path: lazy(() => import("../views/schools-partners/community-partners/path")),
		PathFAQ: lazy(() => import("../views/schools-partners/community-partners/path-faq")),
		PathOtherEducationalAid: lazy(() => import("../views/funding-opportunities/other-educational-aid/path")),
		PaSchoolServices: lazy(() => import("../views/about/contact-us/pa-school-services")),
		PostsecondaryEducationalGratuity: lazy(() => import("../views/funding-opportunities/other-educational-aid/postsecondary-educational-gratuity")),
		PostSecondarySchoolsLanding: lazy(() => import("../views/schools-partners/postsecondary-schools")),
		PostSecondarySchoolsFedWorkStudy: lazy(() => import("../views/schools-partners/postsecondary-schools/federal-work-study")),
		PostSecondarySchoolsFosterEd: lazy(() => import("../views/schools-partners/postsecondary-schools/foster-education")),
		PostSecondarySchoolsFosterEdFAQ: lazy(() => import("../views/schools-partners/postsecondary-schools/foster-education-faq")),
		PostSecondarySchoolsAct101: lazy(() => import("../views/schools-partners/postsecondary-schools/act-101")),
		PostSecondarySchoolsChangeOfOwnership: lazy(() => import("../views/schools-partners/postsecondary-schools/summary-of-change-of-ownership")),
		PostSecondarySchoolsGrantEligibility: lazy(() => import("../views/schools-partners/postsecondary-schools/grant-eligibility")),
		PostSecondarySchoolsIagProgram: lazy(() => import("../views/schools-partners/postsecondary-schools/iag-program")),
		PostSecondarySchoolsPaTip: lazy(() => import("../views/schools-partners/postsecondary-schools/pa-tip")),
		PostSecondarySchoolsProgramReviewProcess: lazy(() => import("../views/schools-partners/postsecondary-schools/program-review-process")),
		PostSecondarySchoolsRTSS: lazy(() => import("../views/schools-partners/postsecondary-schools/rtss")),
		PaHelpsInfoFAQ: lazy(() => import("../views/schools-partners/primary-schools/pa-helps-info/faq")),
		PaHelpsInfoLanding: lazy(() => import("../views/schools-partners/primary-schools/pa-helps-info")),
		PAHelpsParticipatingInstitutions: lazy(() => import("../views/schools-partners/primary-schools/pa-helps-info/participating-institutions")),
		ParentsStudentsHelpEvents: lazy(() => import("../views/tools-resources/parents-students/help-events")),
		PaStateEmployeeDiscount: lazy(() => import("../views/orphans/pa-state-employee-discount")),
		PaStudentTeacherSupportProgramInfo: lazy(() => import("../views/schools-partners/primary-schools/pa-student-teacher-support-program-info")),
		PaTipFaq: lazy(() => import("../views/funding-opportunities/pa-tip/pa-tip-faq")),
		PaTipLanding: lazy(() => import("../views/funding-opportunities/pa-tip/index")),
		PaTipPrepare: lazy(() => import("../views/funding-opportunities/pa-tip/prepare")),
		PaStudentTeacherSupportProgramLanding: lazy(() => import("../views/funding-opportunities/pa-student-teacher-support-program")),
		PaStudentTeacherSupportProgramFAQ: lazy(() => import("../views/funding-opportunities/pa-student-teacher-support-program/faq")),
		PaStudentTeacherParticipatingInstitutions: lazy(() => import("../views/funding-opportunities/pa-student-teacher-support-program/participating-institutions")),
		PaTipApply: lazy(() => import("../views/funding-opportunities/pa-tip/apply")),
		PennWatchAct: lazy(() => import("../views/about/pennwatch-act")),
		PressReleases: lazy(() => import("../views/about/press-releases")),
		PressReleasesArchive: lazy(() => import("../views/about/press-releases/archive")),
		PrimarySchoolsLanding: lazy(() => import("../views/schools-partners/primary-schools")),
		PrivacyPolicy: lazy(() => import("../views/about/privacy-policy")),
		RedirectCreate: lazy(() => import("../views/tools-resources/account-access/redirect-create")),
		RedirectSignIn: lazy(() => import("../views/tools-resources/account-access/redirect-sign-in")),
		RemoteAccessAgreement: lazy(() => import("../views/orphans/raa")),
		ReportsStatisticsLanding: lazy(() => import("../views/about/reports-statistics/index")),
		RefinanceFAQ: lazy(() => import("../views/student-loans/pa-forward/refinance-faq")),
		RightToKnow: lazy(() => import("../views/about/right-to-know")),
		RtssLanding: lazy(() => import("../views/funding-opportunities/other-educational-aid/rtss/index")),
		RtssPrepare: lazy(() => import("../views/funding-opportunities/other-educational-aid/rtss/prepare")),
		RtssStudentFAQ: lazy(() => import("../views/funding-opportunities/other-educational-aid/rtss/student-faq")),
		SchoolsPartnersLanding: lazy(() => import("../views/schools-partners")),
		SchoolsPartnersTraining: lazy(() => import("../views/schools-partners/training")),
		Selection: lazy(() => import("../views/funding-opportunities/loan-relief-for-nurses/selection")),
		SGPAfterYouApplyRenew: lazy(() => import("../views/grants/state-grant-program/after-you-apply-renew")),
		SGPApplyRenew: lazy(() => import("../views/grants/state-grant-program/apply-renew")),
		SGPFAQ: lazy(() => import("../views/grants/state-grant-program/state-grant-faq")),
		SGPForms: lazy(() => import("../views/grants/state-grant-program/forms")),
		SGPSummerGrantProgram: lazy(() => import("../views/grants/state-grant-program/summer-grant-program")),
		Sitemap: lazy(() => import("../views/about/site-map")),
		Slrn: lazy(() => import("../views/schools-partners/community-partners/slrn")),
		SLRNFAQ: lazy(() => import("../views/funding-opportunities/loan-relief-for-nurses/slrn-faq")),
		SmartBorrowing: lazy(() => import("../views/student-loans/pa-forward/smart-borrowing")),
		SpookyNook: lazy(() => import("../views/orphans/spooky-nook")),
		StateGrantProgram: lazy(() => import("../views/about/contact-us/state-grant-program")),
		StateGrantProgramPrepare: lazy(() => import("../views/grants/state-grant-program/prepare")),
		StateGrantTraining: lazy(() => import("../views/schools-partners/training/state-grant-training")),
		StudentAidPage: lazy(() => import("../views/studentaid/index")),
		StudentAidGuide: lazy(() => import("../views/college-planning/student-aid-guide")),
		StudentLoansDefaultedLoans: lazy(() => import("../views/student-loans/defaulted-loans")),
		StudentLoansLanding: lazy(() => import("../views/student-loans")),
		StudentLoansPaForward: lazy(() => import("../views/student-loans/pa-forward")),
		SummaryOfPaStateGrantEligibility: lazy(() => import("../views/schools-partners/postsecondary-schools/summary-of-pa-state-grant-eligibility")),
		SupplementalReport: lazy(() => import("../views/about/investor-information/supplemental-report")),
		Swsp: lazy(() => import("../views/schools-partners/community-partners/swsp")),
		TaxExemptReports: lazy(() => import("../views/about/investor-information/tax-exempt-reports")),
		TefraNotices: lazy(() => import("../views/about/investor-information/tefra-notices")),
		ToolsResourcesLanding: lazy(() => import("../views/tools-resources")),
		ToolsResourcesPaForwardToolkit: lazy(() => import("../views/tools-resources/pa-forward-toolkit")),
		ToolsResourcesParentsStudents: lazy(() => import("../views/tools-resources/parents-students")),
		ToolsResourcesPodcasts: lazy(() => import("../views/tools-resources/podcasts")),
		ToolsResourcesPodcastsArchive: lazy(() => import("../views/tools-resources/podcasts/archive")),
		ToolsResourcesPodcastsDisclaimer: lazy(() => import("../views/tools-resources/podcasts/disclaimer")),
		ToolsResourcesSmartBorrowing: lazy(() => import("../views/tools-resources/mysmartborrowing")),
		ToolsResourcesToolKitLanding: lazy(() => import("../views/tools-resources/toolkit-landing")),
		ToolsResourcesStudentLoanNotificationTool: lazy(() => import("../views/tools-resources/student-loan-notification-tool")),
		TrainingEvents: lazy(() => import("../views/schools-partners/training/training-events")),
		UndergraduateFAQ: lazy(() => import("../views/student-loans/pa-forward/undergraduate-faq")),
		VirtualPage: lazy(() => import("../views/orphans/virtual")),
		Workshops: lazy(() => import("../views/schools-partners/training/workshops")),
		WorkStudyEmployersLanding: lazy(() => import("../views/schools-partners/postsecondary-schools/work-study-employers/index")),
		WorkStudyEmployersOnlineAccess: lazy(() => import("../views/schools-partners/postsecondary-schools/work-study-employers/online-access")),
		WorkStudyEmployersProgramMaterials: lazy(() => import("../views/schools-partners/postsecondary-schools/work-study-employers/program-materials")),
		WorkStudyEmploymentLanding: lazy(() => import("../views/funding-opportunities/work-study-employment")),
		WorkStudyEmploymentPrepare: lazy(() => import("../views/funding-opportunities/work-study-employment/prepare")),
		WorkStudyFaq: lazy(() => import("../views/funding-opportunities/work-study-employment/work-study-faq"))
	},
	routeDataArray = [
		{
			path: "/",
			element: <viewComponents.HomePage />
		},
		{
			label: "What's Next-Student Aid",
			path: "studentaid",
			children: [
				{
					index: true,
					element: <viewComponents.StudentAidPage />
				}
			]
		},
		{
			label: "About",
			path: "about",
			children: [
				{
					index: true,
					element: <viewComponents.AboutLanding />
				},
				{
					label: "About Us",
					path: "about-us",
					children: [
						{
							index: true,
							element: <viewComponents.AboutUs />
						}
					]
				},
				{
					label: "About Careers Page",
					path: "careers",
					children: [
						{
							index: true,
							element: <viewComponents.AboutCareers />
						}
					]
				},
				{
					path: "board-members",
					label: "Board Members",
					children: [
						{
							index: true,
							element: <viewComponents.BoardMembers />
						},
						{
							path: "board-meetings",
							label: "Board Meetings",
							children: [
								{
									index: true,
									element: <viewComponents.BoardMeetings />
								}
							]
						}
					]
				},
				{
					label: "Careers",
					path: "careers",
					children: [
						{
							index: true,
							element: <viewComponents.PageNotFound />
						},
						{
							label: "Redirect",
							path: "redirect",
							children: [
								{
									index: true,
									element: <viewComponents.CareersRedirect />
								}
							]
						},
						{
							path: "career-faq",
							label: "Career FAQs",
							children: [
								{
									index: true,
									element: <viewComponents.CareerFAQ />
								}
							]
						},
						{
							path: "employee-benefits",
							label: "Employee Benefits",
							children: [
								{
									index: true,
									element: <viewComponents.EmployeeBenefits />
								}
							]
						},
						{
							path: "equal-opportunity-employment",
							label: "Equal Opportunity & Affirmative Action Employer",
							children: [
								{
									index: true,
									element: <viewComponents.EqualOpportunityEmployment />
								}
							]
						}
					]
				},
				{
					label: "Contact Us",
					path: "contact-us",
					children: [
						{
							index: true,
							element: <viewComponents.AboutContactUs />
						},
						{
							label: "Office of Consumer Advocacy",
							path: "office-consumer-advocacy",
							children: [
								{
									index: true,
									element: <viewComponents.AboutContactOfficeConsumerAdvocacy />
								}
							]
						},
						{
							label: "Office of Consumer Advocacy Resources",
							path: "office-consumer-advocacy-resources",
							children: [
								{
									index: true,
									element: <viewComponents.OfficeConsumerAdvocacyResources />
								}
							]
						},
						{
							label: "Pennsylvania School Services",
							path: "pa-school-services",
							children: [
								{
									index: true,
									element: <viewComponents.PaSchoolServices />
								}
							]
						},
						{
							label: "Lenders",
							path: "lenders",
							children: [
								{
									index: true,
									element: <viewComponents.AboutContactLenders />
								}
							]
						},
						{
							label: "Human Resources",
							path: "human-resources",
							children: [
								{
									index: true,
									element: <viewComponents.HumanResources />
								}
							]
						},
						{
							label: "Default Collections",
							path: "default-collections",
							children: [
								{
									index: true,
									element: <viewComponents.DefaultCollections />
								}
							]
						},
						{
							label: "State Grant Program",
							path: "state-grant-program",
							children: [
								{
									index: true,
									element: <viewComponents.StateGrantProgram />
								}
							]
						}
					]
				},
				{
					path: "press-releases",
					label: "PHEAA Press Releases",
					children: [
						{
							index: true,
							element: <viewComponents.PressReleases />
						},
						{
							path: "archive",
							label: "Archive",
							children: [
								{
									index: true,
									element: <viewComponents.PressReleasesArchive />
								}
							]
						}
					]
				},
				{
					path: "reports-statistics",
					label: "Reports & Statistical Studies",
					children: [
						{
							index: true,
							element: <viewComponents.ReportsStatisticsLanding />
						}
					]
				},
				{
					label: "Executive Team",
					path: "executive-team",
					children: [
						{
							index: true,
							element: <viewComponents.ExecutiveTeam />
						}
					]
				},
				{
					label: "Online Services Terms of Use",
					path: "online-services-terms-of-use",
					children: [
						{
							index: true,
							element: <viewComponents.OnlineServicesTermsOfUse />
						}
					]
				},
				{
					label: "PennWATCH Act",
					path: "pennwatch-act",
					children: [
						{
							index: true,
							element: <viewComponents.PennWatchAct />
						}
					]
				},
				{
					label: "Privacy Policy",
					path: "privacy-policy",
					children: [
						{
							index: true,
							element: <viewComponents.PrivacyPolicy />
						}
					]
				},
				{
					label: "Doing Business with PHEAA",
					path: "doing-business-with-pheaa",
					children: [
						{
							index: true,
							element: <viewComponents.DoingBusinessWithPheaa />
						}
					]
				},
				{
					label: "Right to Know",
					path: "right-to-know",
					children: [
						{
							index: true,
							element: <viewComponents.RightToKnow />
						}
					]
				},
				{
					label: "Investor Information",
					path: "investor-information",
					children: [
						{
							index: true,
							element: <viewComponents.InvestorInformationLanding />
						},
						{
							label: "PHEAA Financial Reports",
							path: "financial-reports",
							children: [
								{
									index: true,
									element: <viewComponents.FinancialReports />
								}
							]
						},
						{
							label: "PHEAA Student Loan Trust FRN Issuances",
							path: "frn-trusts",
							children: [
								{
									index: true,
									element: <viewComponents.FrnTrusts />
								}
							]
						},
						{
							label: "PHEAA Notice of Independent Registered Municipal Advisor",
							path: "independent-registered-municipal-advisor",
							children: [
								{
									index: true,
									element: <viewComponents.IndependentRegisteredMunicipalAdvisor />
								}
							]
						},
						{
							label: "PHEAA Net Reject Rate Reports",
							path: "net-reject-rate",
							children: [
								{
									index: true,
									element: <viewComponents.NetRejectRate />
								}
							]
						},
						{
							label: "PHEAA Supplemental Report",
							path: "supplemental-report",
							children: [
								{
									index: true,
									element: <viewComponents.SupplementalReport />
								}
							]
						},
						{
							path: "tax-exempt-reports",
							label: "Tax Exempt Reports",
							children: [
								{
									index: true,
									element: <viewComponents.TaxExemptReports />
								}
							]
						},
						{
							path: "tefra-notices",
							label: "PHEAA Tefra Notices",
							children: [
								{
									index: true,
									element: <viewComponents.TefraNotices />
								}
							]
						}
					]
				},
				{
					label: "Site Map",
					path: "site-map",
					children: [
						{
							index: true,
							element: <viewComponents.Sitemap />
						}
					]
				}
			]
		},
		{
			label: "Grants",
			path: "grants",
			children: [
				{
					index: true,
					element: <viewComponents.GrantsLanding />
				},
				{
					label: "PA State Grant Program",
					path: "state-grant-program",
					children: [
						{
							index: true,
							element: <viewComponents.GrantsStateGrantProgram />
						},
						{
							label: "After You Apply or Renew",
							path: "after-you-apply-renew",
							children: [
								{
									index: true,
									element: <viewComponents.SGPAfterYouApplyRenew />
								}
							]
						},
						{
							label: "Apply or Renew",
							path: "apply-renew",
							children: [
								{
									index: true,
									element: <viewComponents.SGPApplyRenew />
								}
							]
						},
						{
							label: "Documents & Forms",
							path: "forms",
							children: [
								{
									index: true,
									element: <viewComponents.SGPForms />
								}
							]
						},
						{
							label: "Summer PA State Grant Program",
							path: "summer-grant-program",
							children: [
								{
									index: true,
									element: <viewComponents.SGPSummerGrantProgram />
								}
							]
						},
						{
							label: "PA State Grant Program FAQ",
							path: "state-grant-faq",
							children: [
								{
									index: true,
									element: <viewComponents.SGPFAQ />
								}
							]
						},
						{
							label: "Prepare",
							path: "prepare",
							children: [
								{
									index: true,
									element: <viewComponents.StateGrantProgramPrepare />
								}
							]
						}
					]
				},
				{
					label: "GrantUs",
					path: "grant-us",
					children: [
						{
							index: true,
							element: <viewComponents.GrantUs />
						},
						{
							label: "GrantUs FAQ",
							path: "faq",
							children: [
								{
									index: true,
									element: <viewComponents.GrantUsFAQ />
								}
							]
						},
						{
							label: "GrantUs School Training",
							path: "school-training",
							children: [
								{
									index: true,
									element: <viewComponents.GrantUsSchoolTraining />
								}
							]
						},
						{
							label: "GrantUs Student & Family Resources ",
							path: "student-training",
							children: [
								{
									index: true,
									element: <viewComponents.GrantUsStudentTraining />
								}
							]
						}
					]
				}
			]
		},
		{
			label: "College Football",
			path: "college",
			children: [
				{
					index: true,
					element: <viewComponents.CollegeMarketing />
				}
			]
		},
		{
			label: "Hershey Park",
			path: "hersheypark",
			children: [
				{
					index: true,
					element: <viewComponents.Hersheypark />
				}
			]
		},
		{
			label: "Spooky Nook",
			path: "spooky-nook",
			children: [
				{
					index: true,
					element: <viewComponents.SpookyNook />
				}
			]
		},
		{
			label: "PA State Employee Discount",
			path: "pa-state-employee-discount",
			children: [
				{
					index: true,
					element: <viewComponents.PaStateEmployeeDiscount />
				}
			]
		},
		{
			label: "Loan Default",
			path: "loan-default",
			children: [
				{
					index: true,
					element: <viewComponents.PageNotFound />
				},
				{
					label: "PHEAA Default FAQ",
					path: "faq",
					children: [
						{
							index: true,
							element: <viewComponents.LoanDefaultFAQ />
						}
					]
				}
			]
		},
		{
			label: "PA's Low-Cost Way to Pay for College",
			path: "paf-facebook",
			children: [
				{
					index: true,
					element: <viewComponents.PAForwardFacebook />
				}
			]
		},
		{
			label: "PA's Low-Cost Way to Pay for College",
			path: "paf-google",
			children: [
				{
					index: true,
					element: <viewComponents.PAForwardGoogle />
				}
			]
		},
		{
			label: "PA's Low-Cost Way to Pay for College",
			path: "paf-pf004",
			children: [
				{
					index: true,
					element: <viewComponents.PAForwardPF004 />
				}
			]
		},
		{
			label: "PA's Low-Cost Way to Pay for College",
			path: "paf-spotify",
			children: [
				{
					index: true,
					element: <viewComponents.PAForwardSpotify />
				}
			]
		},
		{
			label: "PA's Low-Cost Way to Pay for College",
			path: "paf-twitter",
			children: [
				{
					index: true,
					element: <viewComponents.PAForwardTwitter />
				}
			]
		},
		{
			label: "PHEAA Lender Toolkit",
			path: "lender-toolkit",
			children: [
				{
					index: true,
					element: <viewComponents.LenderToolkit />
				}
			]
		},
		{
			label: "Sign Our Remote Access Agreement",
			path: "raa",
			children: [
				{
					index: true,
					element: <viewComponents.RemoteAccessAgreement />
				}
			]
		},
		{
			label: "Student Loans",
			path: "student-loans",
			children: [
				{
					index: true,
					element: <viewComponents.StudentLoansLanding />
				},
				{
					label: "PA Forward Student Loan Program",
					path: "pa-forward",
					children: [
						{
							index: true,
							element: <viewComponents.StudentLoansPaForward />
						},
						{
							label: "Graduate FAQ",
							path: "graduate-faq",
							children: [
								{
									index: true,
									element: <viewComponents.PaForwardGraduateFAQ />
								}
							]
						},
						{
							label: "PA Forward Student Loans: FAQ",
							path: "faq",
							children: [
								{
									index: true,
									element: <viewComponents.PaForwardFAQ />
								}
							]
						},
						{
							path: "parent-faq",
							label: "Parent FAQ",
							children: [
								{
									index: true,
									element: <viewComponents.ParentFAQ />
								}
							]
						},
						{
							path: "refinance-faq",
							label: "Refinance FAQ",
							children: [
								{
									index: true,
									element: <viewComponents.RefinanceFAQ />
								}
							]
						},
						{
							path: "undergraduate-faq",
							label: "Undergraduate FAQ",
							children: [
								{
									index: true,
									element: <viewComponents.UndergraduateFAQ />
								}
							]
						},
						{
							label: "Smart Borrowing",
							path: "smart-borrowing",
							children: [
								{
									index: true,
									element: <viewComponents.SmartBorrowing />
								}
							]
						},
						{
							label: "PA Forward Terms & Privacy Information",
							path: "privacy-policy",
							children: [
								{
									index: true,
									element: <viewComponents.PaForwardPrivacyPolicy />
								}
							]
						},
						{
							label: "PA Forward Student Loan Disclosures",
							path: "disclosures",
							children: [
								{
									index: true,
									element: <viewComponents.PaForwardDisclosures />
								}
							]
						},
						{
							label: "Current Referral Partners",
							path: "referral-partners",
							children: [
								{
									index: true,
									element: <viewComponents.PaForwardReferralPartners />
								}
							]
						},
						{
							label: "PA Forward Parent Loan Compare",
							path: "parent-loan-compare",
							children: [
								{
									index: true,
									element: <viewComponents.PaForwardParentLoanCompare />
								}
							]
						}
					]
				},
				{
					label: "Loan Default",
					path: "defaulted-loans",
					children: [
						{
							index: true,
							element: <viewComponents.StudentLoansDefaultedLoans />
						}
					]
				}
			]
		},
		{
			label: "College Planning",
			path: "college-planning",
			children: [
				{
					index: true,
					element: <viewComponents.CollegePlanningLanding />
				},
				{
					label: "College Fairs",
					path: "college-fairs",
					children: [
						{
							index: true,
							element: <viewComponents.CollegeFairs />
						}
					]
				},
				{
					label: "Pennsylvania Student Aid Guide",
					path: "student-aid-guide",
					children: [
						{
							index: true,
							element: <viewComponents.StudentAidGuide />
						}
					]
				},
				{
					label: "FAFSA",
					path: "fafsa",
					children: [
						{
							index: true,
							element: <viewComponents.FafsaLanding />
						},
						{
							label: "What to do After You Apply or Renew",
							path: "after-you-apply-renew",
							children: [
								{
									index: true,
									element: <viewComponents.AfterYouApplyRenew />
								}
							]
						},
						{
							label: "FAFSA FAQ",
							path: "fafsa-faq",
							children: [
								{
									index: true,
									element: <viewComponents.FafsaFaq />
								}
							]
						},
						{
							label: "How to Apply or Renew",
							path: "apply-renew",
							children: [
								{
									index: true,
									element: <viewComponents.ApplyRenew />
								}
							]
						},
						{
							label: "Prepare for the FAFSA",
							path: "prepare",
							children: [
								{
									index: true,
									element: <viewComponents.FafsaPrepare />
								}
							]
						},
						{
							label: "FAFSA Completion Help Events",
							path: "help-events",
							children: [
								{
									index: true,
									element: <viewComponents.FafsaHelpEvents />
								}
							]
						}
					]
				},
				{
					label: "Five Steps to Financial Aid",
					path: "five-steps",
					children: [
						{
							index: true,
							element: <viewComponents.FiveSteps />
						}
					]
				},
				{
					label: "EducationPlanner",
					path: "education-planner",
					children: [
						{
							index: true,
							element: <viewComponents.EducationPlannerLanding />
						}
					]
				}
			]
		},
		{
			label: "Funding Opportunities",
			path: "funding-opportunities",
			children: [
				{
					index: true,
					element: <viewComponents.FundingOpportunitiesLanding />
				},
				{
					label: "Aid for Military and Pennsylvania National Guard",
					path: "aid-for-military-national-guard",
					children: [
						{
							index: true,
							element: <viewComponents.AidForMilitaryNationalGuard />
						},
						{
							label: "Children of POW or MIA Soldiers",
							path: "children-pow-mia-soldiers",
							children: [
								{
									index: true,
									element: <viewComponents.ChildrenPowMiaSoldiers />
								}
							]
						},
						{
							label: "PA National Guard Military Family Education Program (MFEP)",
							path: "military-family-education",
							children: [
								{
									index: true,
									element: <viewComponents.MilitaryFamilyEducation />
								}
							]
						},
						{
							label: "PA National Guard Educational Assistance Program (EAP) ",
							path: "national-guard-eap",
							children: [
								{
									index: true,
									element: <viewComponents.NationalGuardEAP />
								}
							]
						},
						{
							label: "Pennsylvania State Grant Program",
							path: "state-grant-program",
							children: [
								{
									index: true,
									element: <viewComponents.MilitaryStateGrantProgram />
								}
							]
						}
					]
				},
				{
					label: "Other Educational Aid",
					path: "other-educational-aid",
					children: [
						{
							index: true,
							element: <viewComponents.OtherEducationalAid />
						},
						{
							label: "Active Volunteer Tuition & Loan Assistance Program",
							path: "active-volunteer-program",
							children: [
								{
									index: true,
									element: <viewComponents.ActiveVolunteerProgram />
								}
							]
						},
						{
							label: "PA Ready to Succeed Scholarship (RTSS) Program",
							path: "rtss",
							children: [
								{
									index: true,
									element: <viewComponents.RtssLanding />
								},
								{
									label: "Participating Institutions",
									path: "participating-institutions",
									children: [
										{
											index: true,
											element: <viewComponents.ParticipatingInstitutions />
										}
									]
								},
								{
									label: "Prepare",
									path: "prepare",
									children: [
										{
											index: true,
											element: <viewComponents.RtssPrepare />
										}
									]
								},
								{
									label: "Student FAQ",
									path: "student-faq",
									children: [
										{
											index: true,
											element: <viewComponents.RtssStudentFAQ />
										}
									]
								}
							]
						},
						{
							label: "PA Blind or Deaf Higher Education Beneficiary Grant (BDBG) Program",
							path: "blind-deaf",
							children: [
								{
									index: true,
									element: <viewComponents.BlindDeaf />
								}
							]
						},
						{
							label: "Chafee Education & Training Grant (Chafee ETG) Program",
							path: "chafee-program",
							children: [
								{
									index: true,
									element: <viewComponents.ChafeeProgram />
								}
							]
						},
						{
							label: "PA Fostering Independence Tuition Waiver (FosterEd) Program",
							path: "foster-education",
							children: [
								{
									index: true,
									element: <viewComponents.FosterEducation />
								}
							]
						},
						{
							label: "Foster Education FAQ",
							path: "foster-education-faq",
							children: [
								{
									index: true,
									element: <viewComponents.FosterEducationFAQ />
								}
							]
						},
						{
							label: "PA New Economy Technology Scholarship (NETS) Program",
							path: "nets",
							children: [
								{
									index: true,
									element: <viewComponents.Nets />
								}
							]
						},
						{
							label: "PA New Economy Technology Scholarship (NETS) Program Repayment",
							path: "nets-repayment",
							children: [
								{
									index: true,
									element: <viewComponents.NetsRepayment />
								}
							]
						},
						{
							label: "PA NETS Program FAQ",
							path: "nets-faq",
							children: [
								{
									index: true,
									element: <viewComponents.NetsFaq />
								}
							]
						}, {
							label: "PA Postsecondary Educational Gratuity Program (PEGP)",
							path: "postsecondary-educational-gratuity",
							children: [
								{
									index: true,
									element: <viewComponents.PostsecondaryEducationalGratuity />
								}
							]
						},
						{
							label: "PA Partnerships for Access to Higher Education (PATH) Program Partners",
							path: "participating-path-partners",
							children: [
								{
									index: true,
									element: <viewComponents.ParticipatingPathPartners />
								}
							]
						},
						{
							label: "PA Partnerships for Access to Higher Education (PATH) Program",
							path: "path",
							children: [
								{
									index: true,
									element: <viewComponents.PathOtherEducationalAid />
								}
							]
						}
					]
				},
				{
					label: "Loan Forgiveness",
					path: "loan-forgiveness",
					children: [
						{
							index: true,
							element: <viewComponents.LoanForgiveness />
						}
					]
				},
				{
					label: "PA Student Loan Relief for Nurses (SLRN) Program",
					path: "loan-relief-for-nurses",
					children: [
						{
							index: true,
							element: <viewComponents.LoanReliefNurses />
						},
						{
							label: "Appeals",
							path: "appeals",
							children: [
								{
									index: true,
									element: <viewComponents.Appeals />
								}
							]
						},
						{
							label: "Selection",
							path: "selection",
							children: [
								{
									index: true,
									element: <viewComponents.Selection />
								}
							]
						},
						{
							label: "Student FAQ",
							path: "slrn-faq",
							children: [
								{
									index: true,
									element: <viewComponents.SLRNFAQ />
								}
							]
						}
					]
				},
				{
					path: "pa-tip",
					label: "PA Targeted Industry Program (PA-TIP)",
					children: [
						{
							index: true,
							element: <viewComponents.PaTipLanding />
						},
						{
							label: "PA-TIP FAQ",
							path: "pa-tip-faq",
							children: [
								{
									index: true,
									element: <viewComponents.PaTipFaq />
								}
							]
						},
						{
							label: "Apply",
							path: "apply",
							children: [
								{
									index: true,
									element: <viewComponents.PaTipApply />
								}
							]
						},
						{
							path: "eligible-schools",
							label: "Eligible Schools & Approved Programs of Study",
							children: [
								{
									index: true,
									element: <viewComponents.EligibleSchools />
								}
							]
						},
						{
							label: "PA-TIP Prepare",
							path: "prepare",
							children: [
								{
									index: true,
									element: <viewComponents.PaTipPrepare />
								}
							]
						},
						{
							label: "After You Apply",
							path: "after-you-apply",
							children: [
								{
									index: true,
									element: <viewComponents.AfterYouApplyPaTip />
								}
							]
						}
					]
				},
				{
					label: "PA Student Teacher Support Program",
					path: "pa-student-teacher-support-program",
					children: [
						{
							index: true,
							element: <viewComponents.PaStudentTeacherSupportProgramLanding />
						},
						{
							label: "Student FAQ",
							path: "faq",
							children: [
								{
									index: true,
									element: <viewComponents.PaStudentTeacherSupportProgramFAQ />
								}
							]
						},
						{
							label: "Participating Institutions",
							path: "participating-institutions",
							children: [
								{
									index: true,
									element: <viewComponents.PaStudentTeacherParticipatingInstitutions />
								}
							]
						}
					]
				},
				{
					label: "PA State Work-Study Program (SWSP)",
					path: "work-study-employment",
					children: [
						{
							index: true,
							element: <viewComponents.WorkStudyEmploymentLanding />
						},
						{
							label: "SWSP Prepare",
							path: "prepare",
							children: [
								{
									index: true,
									element: <viewComponents.WorkStudyEmploymentPrepare />
								}
							]
						},
						{
							label: "SWSP Apply",
							path: "apply",
							children: [
								{
									index: true,
									element: <viewComponents.Apply />
								}
							]
						},
						{
							label: "SWSP After you Apply",
							path: "after-you-apply",
							children: [
								{
									index: true,
									element: <viewComponents.AfterYouApplyWorkStudyEmployment />
								}
							]
						},
						{
							label: "FAQ",
							path: "work-study-faq",
							children: [
								{
									index: true,
									element: <viewComponents.WorkStudyFaq />
								}
							]
						}
					]
				},
				{
					label: "PA Mental Health Education Learning Program in Schools (PA HELPS)",
					path: "pa-helps",
					children: [
						{
							index: true,
							element: <viewComponents.PaHelpsLanding />
						}
					]
				},
				{
					label: "Grow PA Scholarship Grant Program",
					path: "grow-pa-scholarship-grant-program",
					children: [
						{
							index: true,
							element: <viewComponents.GrowPA />
						},
						{
							label: "FAQ",
							path: "faq",
							children: [
								{
									index: true,
									element: <viewComponents.GrowPAFAQ />
								}
							]
						}
					]
				},
				{
					label: "Grow PA Tuition Waiver Program",
					path: "grow-pa-tuition-waiver",
					children: [
						{
							index: true,
							element: <viewComponents.GrowPATuitionWaiver />
						}
					]
				}
			]
		},
		{
			label: "Tools & Resources",
			path: "tools-resources",
			children: [
				{
					index: true,
					element: <viewComponents.ToolsResourcesLanding />
				},
				{
					label: "AES Account Access",
					path: "account-access",
					children: [
						{
							index: true,
							element: <viewComponents.AccountAccessLanding />
						},
						{
							label: "You are being redirected to aesSuccess.org",
							path: "redirect-create",
							children: [
								{
									index: true,
									element: <viewComponents.RedirectCreate />
								}
							]
						},
						{
							label: "You are being redirected to aesSuccess.org",
							path: "redirect-sign-in",
							children: [
								{
									index: true,
									element: <viewComponents.RedirectSignIn />
								}
							]
						}
					]
				},
				{
					label: "MySmartBorrowing",
					path: "mysmartborrowing",
					children: [
						{
							index: true,
							element: <viewComponents.ToolsResourcesSmartBorrowing />
						}
					]
				},
				{
					label: "PA Forward Student Loan Program Toolkit",
					path: "pa-forward-toolkit",
					children: [
						{
							index: true,
							element: <viewComponents.ToolsResourcesPaForwardToolkit />
						}
					]
				},
				{
					label: "Financial Aid Toolkit",
					path: "toolkit-landing",
					children: [
						{
							index: true,
							element: <viewComponents.ToolsResourcesToolKitLanding />
						}
					]
				},
				{
					label: "Student Loan Notification Tool",
					path: "student-loan-notification-tool",
					children: [
						{
							index: true,
							element: <viewComponents.ToolsResourcesStudentLoanNotificationTool />
						}
					]
				},
				{
					label: "Financial Aid Resources for Parents & Students",
					path: "parents-students",
					children: [
						{
							index: true,
							element: <viewComponents.ToolsResourcesParentsStudents />
						},
						{
							label: "Financial Aid Night Help Events",
							path: "help-events",
							children: [
								{
									index: true,
									element: <viewComponents.ParentsStudentsHelpEvents />
								}
							]
						}
					]
				},
				{
					label: "Counselors & Partners Resources",
					path: "counselors-partners",
					children: [
						{
							index: true,
							element: <viewComponents.CounselorsPartners />
						},
						{
							label: "Financial Aid Night",
							path: "financial-aid-night",
							children: [
								{
									index: true,
									element: <viewComponents.CounselorsPartnersFinancialAidNight />
								}
							]
						},
						{
							label: "FAFSA Toolkit",
							path: "fafsa-events",
							children: [
								{
									index: true,
									element: <viewComponents.CounselorsPartnersFafsaEvents />
								}
							]
						}
					]
				},
				{
					label: "PHEAA Podcast",
					path: "podcasts",
					children: [
						{
							index: true,
							element: <viewComponents.ToolsResourcesPodcasts />
						},
						{
							label: "PHEAA Podcast Archive",
							path: "archive",
							children: [
								{
									index: true,
									element: <viewComponents.ToolsResourcesPodcastsArchive />
								}
							]
						},
						{
							label: "Podcast Disclaimer",
							path: "disclaimer",
							children: [
								{
									index: true,
									element: <viewComponents.ToolsResourcesPodcastsDisclaimer />
								}
							]
						}
					]
				}
			]
		},
		{
			path: "schools-partners",
			label: "Schools & Partners",
			children: [
				{
					index: true,
					element: <viewComponents.SchoolsPartnersLanding />
				},
				{
					label: "Community Partners",
					path: "community-partners",
					children: [
						{
							index: true,
							element: <viewComponents.CommunityPartnersLanding />
						},
						{
							label: "PA PATH Partner FAQ",
							path: "path-faq",
							children: [
								{
									index: true,
									element: <viewComponents.PathFAQ />
								}
							]
						},
						{
							label: "PA Student Loan Relief for Nurses (SLRN) Employer Information",
							path: "slrn",
							children: [
								{
									index: true,
									element: <viewComponents.Slrn />
								}
							]
						},
						{
							label: "PA State Work-Study Program (SWSP)",
							path: "swsp",
							children: [
								{
									index: true,
									element: <viewComponents.Swsp />
								}
							]
						},
						{
							label: "PA Partnerships for Access to Higher Education (PATH) Program",
							path: "path",
							children: [
								{
									index: true,
									element: <viewComponents.Path />
								}
							]
						}
					]
				},
				{
					label: "K-12 Counselors",
					path: "k12-counselors",
					children: [
						{
							index: true,
							element: <viewComponents.K12Landing />
						},
						{
							label: "Helpful Websites",
							path: "helpful-websites",
							children: [
								{
									index: true,
									element: <viewComponents.HelpfulWebsites />
								}
							]
						},
						{
							label: "Higher Education Access Partners",
							path: "higher-education-access-partners",
							children: [
								{
									index: true,
									element: <viewComponents.HigherEducationAccessPartners />
								}
							]
						},
						{
							label: "Third Grade School Materials",
							path: "materials-3rd-grade",
							children: [
								{
									index: true,
									element: <viewComponents.Materials3rdGrade />
								}
							]
						},
						{
							label: "Fifth Grade School Materials",
							path: "materials-5th-grade",
							children: [
								{
									index: true,
									element: <viewComponents.Materials5thGrade />
								}
							]
						},
						{
							label: "Eighth Grade School Materials",
							path: "materials-8th-grade",
							children: [
								{
									index: true,
									element: <viewComponents.Materials8thGrade />
								}
							]
						},
						{
							label: "Eleventh Grade School Materials",
							path: "materials-11th-grade",
							children: [
								{
									index: true,
									element: <viewComponents.Materials11thGrade />
								}
							]
						},
						{
							label: "PHEAA Resources",
							path: "pheaa-resources",
							children: [
								{
									index: true,
									element: <viewComponents.K12PheaaResources />
								}
							]
						},
						{
							label: "Publications",
							path: "publications",
							children: [
								{
									index: true,
									element: <viewComponents.K12Publications />
								}
							]
						}
					]
				},
				{
					label: "Resources and Training",
					path: "training",
					children: [
						{
							index: true,
							element: <viewComponents.SchoolsPartnersTraining />
						},
						{
							label: "Financial Aid Workshops for Educators",
							path: "workshops",
							children: [
								{
									index: true,
									element: <viewComponents.Workshops />
								}
							]
						},
						{
							path: "training-events",
							label: "Training Events ",
							children: [
								{
									index: true,
									element: <viewComponents.TrainingEvents />
								}
							]
						},
						{
							label: "PA State Grant and Special Programs Resources & Training",
							path: "state-grant-training",
							children: [
								{
									index: true,
									element: <viewComponents.StateGrantTraining />
								}
							]
						}
					]
				},
				{
					label: "Postsecondary Schools",
					path: "postsecondary-schools",
					children: [
						{
							index: true,
							element: <viewComponents.PostSecondarySchoolsLanding />
						},
						{
							label: "PA Act 101 Program",
							path: "act-101",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsAct101 />
								}
							]
						},
						{
							label: "PA Ready to Succeed Scholarship (RTSS) Program Schools",
							path: "rtss",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsRTSS />
								}
							]
						},
						{
							label: "PA Targeted Industry Program Schools",
							path: "pa-tip",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsPaTip />
								}
							]
						},
						{
							label: "PA State Grant Eligibility for Change of Ownership",
							path: "summary-of-change-of-ownership",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsChangeOfOwnership />
								}
							]
						},
						{
							label: "PA State Grant Program Eligibility Requirements for Institutions",
							path: "summary-of-pa-state-grant-eligibility",
							children: [
								{
									index: true,
									element: <viewComponents.SummaryOfPaStateGrantEligibility />
								}
							]
						},
						{
							label: "PA Fostering Independence Tuition Waiver (FosterEd) Program",
							path: "foster-education",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsFosterEd />
								}
							]
						},
						{
							label: "PHEAA Federal Work-Study Programs",
							path: "federal-work-study",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsFedWorkStudy />
								}
							]
						},
						{
							label: "PA State Work-Study Program (SWSP)-Employers",
							path: "work-study-employers",
							children: [
								{
									index: true,
									element: <viewComponents.WorkStudyEmployersLanding />
								},
								{
									label: "SWSP Online Access",
									path: "online-access",
									children: [
										{
											index: true,
											element: <viewComponents.WorkStudyEmployersOnlineAccess />
										}
									]
								},
								{
									label: "Employer Tools & Resources",
									path: "program-materials",
									children: [
										{
											index: true,
											element: <viewComponents.WorkStudyEmployersProgramMaterials />
										}
									]
								}
							]
						},
						{
							label: "PA Institutional Assistance Grants (IAG) Program",
							path: "iag-program",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsIagProgram />
								}
							]
						},
						{
							label: "State Grant Eligibility",
							path: "grant-eligibility",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsGrantEligibility />
								}
							]
						},
						{
							label: "Program Review Process",
							path: "program-review-process",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsProgramReviewProcess />
								}
							]
						},
						{
							label: "PA Fostering Independence Tuition Waiver (FosterEd) Program FAQ",
							path: "foster-education-faq",
							children: [
								{
									index: true,
									element: <viewComponents.PostSecondarySchoolsFosterEdFAQ />
								}
							]
						}
					]
				},
				{
					label: "Primary Schools",
					path: "primary-schools",
					children: [
						{
							index: true,
							element: <viewComponents.PrimarySchoolsLanding />
						},
						{
							label: "PA Student Teacher Support Program Information",
							path: "pa-student-teacher-support-program-info",
							children: [
								{
									index: true,
									element: <viewComponents.PaStudentTeacherSupportProgramInfo />
								}
							]
						},
						{
							label: "PA Mental Health Education Learning Program in Schools (PA HELPS) Information",
							path: "pa-helps-info",
							children: [
								{
									index: true,
									element: <viewComponents.PaHelpsInfoLanding />
								},
								{
									label: "FAQ",
									path: "faq",
									children: [
										{
											index: true,
											element: <viewComponents.PaHelpsInfoFAQ />
										}
									]
								},
								{
									label: "PA HELPS Currently Participating Institutions",
									path: "participating-institutions",
									children: [
										{
											index: true,
											element: <viewComponents.PAHelpsParticipatingInstitutions />
										}
									]
								}
							]
						}
					]
				}
			]
		},
		{
			label: "Financial Aid Webinars",
			path: "virtual",
			children: [
				{
					index: true,
					element: <viewComponents.VirtualPage />
				}
			]
		},
		{
			label: "PHEAA Products",
			path: "products",
			children: [
				{
					index: true,
					element: <viewComponents.CommercialProducts />
				},
				{
					path: ":sectionId",
					element: <viewComponents.CommercialProducts />
				}
			],
			exclude: true
		},
		{
			path: "*",
			element: <viewComponents.PageNotFound />
		}
	];


export { routeDataArray, viewComponents };